<template>
  <div class="screen-deactivate-account has-tab-bar">
    <app-bar title="Deactiveren" :show-back-button="true" />
    <div class="container">
      <p class="margin-top-10">
        <strong>Als je jouw account deactiveert:</strong>
        <br />
        Al je aanmeldingen die je momenteel hebt worden automatisch geannuleerd.
        Je profiel wordt verborgen. Sommige informatie, zoals je geplaatste
        berichten, blijven wellicht zichtbaar voor anderen. We bewaren je
        accountgegevens zodat je jouw account in de toekomst kan her-activeren.
      </p>

      <p>
        <strong>Om je account te verwijderen:</strong>
        <br />
        Als je jouw account permanent wilt sluiten en verwijderen, neem dan
        contact met ons op. Als je jouw account verwijdert verifiëren we je
        identiteit voor veiligheidsredenen voordat we je verwijderingsverzoek
        accepteren. Je kan niet her-activeren, gegevens herstellen, of opnieuw
        toegang krijgen tot je account nadat deze is verwijderd.
      </p>

      <p>
        Al je aanmeldingen die je momenteel hebt worden automatisch geannuleerd.
        We beginnen te werken aan je verwijderingsverzoek nadat we deze
        ontvangen, maar sommige van je gegevens blijven misschien behouden in
        ons systeem als dat juridisch verplicht of juridisch toegestaan is. In
        plaats van het verwijderen van bepaalde gegevens, maken we ze wellicht
        anoniem, zodat deze niet meer met jou geassocieerd lijken te worden.
        Sommige informatie, zoals recensies en berichten die je naar andere
        gebruikers hebt gestuurd, blijven wellicht zichtbaar voor anderen.
      </p>

      <p>
        Neem voor het deactiveren of verwijderen van je account contact met ons
        op via
        <a href="mailto:contact@inviplay.nl">contact@inviplay.nl</a>
      </p>
    </div>
    <tab-bar selected="profile" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import TabBar from '@/components/TabBar'

export default {
  name: 'DeactivateAccount',
  components: {
    AppBar,
    TabBar
  }
}
</script>

<style scoped></style>
