<template>
  <div class="screen-password-reset">
    <div class="container">
      <div class="logo">
        <img src="@/assets/images/logo.png" />
      </div>
      <form @submit.prevent="passwordReset">
        <div class="form-group">
          <input
            v-model="password"
            type="password"
            class="form-control no-border"
            placeholder="Nieuw wachtwoord"
            required
          />
        </div>

        <div class="form-group">
          <input
            v-model="passwordRepeat"
            type="password"
            class="form-control no-border"
            placeholder="Herhaal wachtwoord"
            required
          />
        </div>

        <button
          type="submit"
          class="btn btn-white btn-block button-password-reset margin-bottom-15"
        >
          Wachtwoord wijzigen
        </button>
        <p class="text-or">of</p>
        <a
          href="#"
          class="btn btn-green-border-white btn-block button-login"
          @click.prevent="$router.push('/login')"
        >
          terug naar inloggen
        </a>
      </form>
    </div>
    <div class="background-entrance">
      <div class="bottom">
        <div class="circle" />
        <div class="dash" />
      </div>
    </div>
    <modal :show="showSuccesModal" @close="showSuccesModal = false">
      <template v-slot:header>Gelukt!</template>
      <p class="text-sm">
        Het wachtwoord is opnieuw ingesteld, je kunt nu inloggen.
      </p>
      <template v-slot:footer>
        <button
          class="button button-primary w-full"
          @click="$router.push('/login')"
        >
          Inloggen
        </button>
      </template>
    </modal>
    <modal :show="showErrorModal" @close="showErrorModal = false">
      <template v-slot:header>Er ging iets fout...</template>
      <p class="text-sm">
        {{ errorText }}
      </p>
      <template v-slot:footer>
        <button
          class="button button-primary w-full"
          @click="sendPasswordResetLink"
        >
          Stuur mij een nieuwe link
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import { PARTICIPANT_PASSWORD_RESET } from '../store/actions/participant'
import _ from 'lodash'
import Modal from '@/components/Modal'

export default {
  name: 'Register',
  components: { Modal },
  data() {
    return {
      password: null,
      passwordRepeat: null,
      resetToken: this.$route.query['reset-token'],
      showErrorModal: false,
      showSuccesModal: false,
      errorText: undefined
    }
  },
  created() {
    // Redirect to login if token is empty
    if (_.isEmpty(this.resetToken)) {
      alert('Wachtwoord reset token is niet gevonden')
      this.$router.push('/login')
    }
  },
  methods: {
    passwordReset: function () {
      if (this.isValid()) {
        this.$store
          .dispatch(PARTICIPANT_PASSWORD_RESET, {
            newPassword: this.password,
            resetToken: this.resetToken
          })
          .then(() => {
            this.showSuccesModal = true
          })
          .catch((error) => {
            this.showErrorModal = true
            if (error?.code === 'INVALID_TOKEN') {
              this.errorText =
                'De link waarmee je je wachtwoord probeert te wijzigen is verlopen. Klik op onderstaande knop om een nieuwe link aan te vragen om je wachtwoord in te stellen.'
            } else {
              this.errorText =
                'Er ging iets fout bij het wijzigen van je wachtwoord. Klik op onderstaande knop om een nieuwe link aan te vragen en het nog een keer te proberen.'
            }
          })
      }
    },
    sendPasswordResetLink: function () {
      this.$router.push('/password-forgot')
    },
    isValid: function () {
      let isValid = true
      let message = null

      // Check fields are valid
      if (_.isEmpty(this.password)) {
        isValid = false
        message = 'Nieuw wachtwoord is verplicht'
      } else if (_.isEmpty(this.passwordRepeat)) {
        isValid = false
        message = 'Herhaal wachtwoord is verplicht'
      } else if (this.password !== this.passwordRepeat) {
        isValid = false
        message = 'Wachtwoord velden komen niet overeen'
      }

      if (message !== null) {
        alert(message)
      }
      return isValid
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.screen-password-reset .logo {
  width: 270px;
  margin: 0 auto;
  padding: 40px 0;
  max-width: 100%;
}
.screen-password-reset .logo img {
  width: 270px;
  max-width: 100%;
}
.screen-password-reset .text-or {
  color: $ipWhite;
  font-size: 1.25rem;
  margin: 15px 0;
  text-align: center;
}
.screen-password-reset
  .wrapper-activity-list
  .activity-list
  li
  .activity-title {
  color: $ipWhite;
}
.screen-password-reset .button-password-reset {
  margin-top: 15px;
}
.screen-password-reset .button-login {
  margin-bottom: 25px;
}
</style>
