var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"has-tab-bar"},[_c('app-bar',{attrs:{"title":"Welkom","show-back-button":false,"show-profile-button":false}}),(_vm.loading)?_c('spinner-loader'):_vm._e(),_c('div',{staticClass:"container"},[_c('h1',[_vm._v("Maak een wachtwoord aan")]),_c('div',[_vm._v("Met dit wachtwoord kun je vervolgens inloggen op Inviplay")]),_c('input-field',{staticClass:"mt-4",attrs:{"type":"password","default-value":"Wachtwoord"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('input-field',{staticClass:"mt-4",attrs:{"type":"password","default-value":"Herhaal wachtwoord"},model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=$$v},expression:"repeatPassword"}}),(_vm.passwordsNotEqual)?_c('div',{staticClass:"text-red-800 mt-3 text-sm"},[_vm._v(" De wachtwoorden komen niet overeen ")]):_vm._e(),(_vm.passwordFormatError)?_c('div',{staticClass:"text-red-800 mt-3 text-sm"},[_vm._v(" Het wachtwoord moet minimaal 8 tekens, 1 hoofdletter en 1 getal bevatten ")]):_vm._e(),_c('button',{staticClass:"button button-primary mt-8",attrs:{"disabled":_vm.loading || !_vm.password || !_vm.repeatPassword},on:{"click":_vm.createPassword}},[_vm._v(" Maak wachtwoord aan ")])],1),_c('modal',{attrs:{"show":_vm.showErrorModal},on:{"close":function () {
        _vm.showErrorModal = false
        _vm.showNewLinkButton = false
      }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Er ging iets fout...")]},proxy:true},(_vm.showNewLinkButton)?{key:"footer",fn:function(){return [_c('button',{staticClass:"button button-primary w-full",on:{"click":_vm.sendNewPasswordResetLink}},[_vm._v(" Stuur mij een nieuwe link ")]),_c('button',{staticClass:"button button-secondary w-full",on:{"click":function () {
            _vm.showErrorModal = false
            _vm.showNewLinkButton = false
          }}},[_vm._v(" Annuleren ")])]},proxy:true}:null],null,true)},[_c('p',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(_vm.errorText)+" ")])]),_c('modal',{attrs:{"show":_vm.showSuccesModal},on:{"close":function () {
        _vm.showSuccesModal = false
        _vm.showLoginButton = false
      }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("Gelukt!")]},proxy:true},(_vm.showLoginButton)?{key:"footer",fn:function(){return [_c('button',{staticClass:"button button-primary w-full",on:{"click":function($event){return _vm.$router.push('/login')}}},[_vm._v(" Inloggen ")])]},proxy:true}:null],null,true)},[_c('p',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(_vm.successText)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }