<template>
  <div
    class="screen-privacy-policy has-tab-bar"
    :class="{ 'has-tab-bar': showTabBar }"
  >
    <app-bar title="Privacy" :show-back-button="true" />
    <div class="container">
      <h1>Privacyverklaring</h1>

      <p>
        Inviplay respecteert jouw privacy en draagt er zorg voor dat de
        persoonlijke informatie die je aan ons verstrekt, vertrouwelijk en in
        overeenstemming met de toepasselijke wet- en regelgeving, waaronder de
        Algemene Verordening Gegevensbescherming, wordt behandeld. In deze
        privacyverklaring geven wij informatie over hoe wij omgaan met je
        persoonsgegevens.
      </p>

      <h2>Welke gegevens en welk doel</h2>
      <p>
        Wij verwerken je persoonsgegevens uitsluitend voor het doel waarvoor je
        ze aan ons verstrekt en wij verwerken niet meer gegevens dan daarvoor
        nodig zijn. Je persoonsgegevens worden door ons verwerkt ten behoeve van
        de volgende doeleinden:
      </p>
      <ul class="list-inside list-disc my-2">
        <li>Om mensen via de app met elkaar te verbinden om te gaan sporten</li>
        <li>
          Om je te kunnen uitnodigen voor de sporten waarin jij geïnteresseerd
          bent
        </li>
        <li>
          Om te communiceren over sportactiviteiten en nieuwe mogelijkheden op
          Inviplay
        </li>
      </ul>
      <p class="mt-4">
        Omdat wij mensen willen stimuleren zich aan te melden voor activiteiten
        laten we zien hoeveel deelnemers al meedoen aan een activiteit. Om jouw
        privacy te beschermen tonen we hierbij geen achternamen, alleen je
        voornaam en de eerste letter van je achternaam. De organisator van een
        activiteit ziet de achternamen wel. De nieuwsberichten van Inviplay en
        uitnodigingen voor sportactiviteiten worden alleen verzonden als je je
        daarvoor hebt aangemeld. Afmelden kan je eenvoudig zelf via de link
        onderaan de nieuwsberichten, of via je profiel.
      </p>
      <p class="mt-4">
        De nieuwsberichten van Inviplay en uitnodigingen voor sportactiviteiten
        worden alleen verzonden als je je daarvoor hebt aangemeld. Afmelden kan
        je eenvoudig zelf via de link onderaan de nieuwsberichten, of via je
        profiel.
      </p>

      <h2>Financiële gegevens</h2>
      <p>
        Om betalingen af te handelen tussen partijen moet een organisatie een
        banklicentie hebben. Inviplay heeft zelf geen banklicentie en werkt
        daarom samen met Online Payment Platform. Online Payment Platform heeft
        namelijk wel een licentie als betaaldienstverlener van De Nederlandsche
        Bank. Zij verwerken betalingen voor honderden bedrijven, waaronder eBay,
        Marktplaats en dus ook voor Inviplay.
      </p>
      <p class="mt-4">
        Om bedragen te kunnen uitbetalen op jouw rekening heeft Online Payment
        Platform een bevestiging van jouw bankgegevens en ID nodig. Online
        Payment Platform heeft namelijk een licentie als betaaldienstverlener
        van De Nederlandsche Bank. Zij zijn daardoor wettelijk verplicht om een
        bankrekening verificatie te doen van de mensen aan wie zij geld
        uitkeren. Dat heet KYC (“know your customer”). Als je dus uitbetalingen
        wil laten doen via Inviplay wordt je doorgestuurd naar Online Payment
        Platform voor een ID check.
      </p>

      <p class="mt-4">
        Inviplay slaat jouw bankgegevens of ID niet zelf op. Online Payment
        Platform is gespecialiseerd in het verwerken van gevoelige informatie.
        Zij versleutelen de gegevens en bewaren deze op extra beveiligde
        servers. Je gegevens worden alleen gebruikt om je identiteit vast te
        stellen. Zie ook hun beleid:
        <a
          href="https://onlinepaymentplatform.com/nl/privacy"
          target="_blank"
          class="link"
        >
          https://onlinepaymentplatform.com/nl/privacy
        </a>
        en
        <a
          href="https://onlinepaymentplatform.com/nl/veiligheid"
          target="_blank"
          class="link"
        >
          https://onlinepaymentplatform.com/nl/veiligheid
        </a>
        .
      </p>

      <h2>Verstrekking aan derden</h2>
      <p>
        De gegevens die je aan ons geeft kunnen wij aan derde partijen
        (verwerkers) verstrekken als dit noodzakelijk is voor de uitvoering van
        de hierboven beschreven doeleinden.
      </p>
      <p class="mt-4">
        De derde partijen waar wij gegevens mee delen zijn AWF Software BV,
        beheerders van sportlocaties en organisatoren van activiteiten waar jij
        je voor opgeeft. AWF Software BV doet een deel van de technische
        ontwikkeling van Inviplay en IT ondersteuning. Op het moment dat je een
        sportlocatie boekt hebben beheerders uiteraard inzage in een aantal
        persoonsgegevens (naam, achternaam) zodat ze weten wie er gebruik maakt
        van hun sportlocatie. Organisatoren van activiteiten hebben inzicht in
        deelnemerslijsten (voor- en achternaam en mailadressen), zodat ze
        deelnemers kunnen bereiken mocht dat nodig zijn.
      </p>
      <p class="mt-4">
        Wij maken met deze derde partijen de nodige afspraken om de beveiliging
        van je persoonsgegevens te waarborgen. Verder zullen wij de door jouw
        verstrekte gegevens niet aan andere partijen verstrekken, tenzij dit
        wettelijk verplicht is. Daarbuiten zullen wij geen persoonsgegevens
        delen met derden zonder dat wij je daar uitdrukkelijk toestemming voor
        vragen. Wij verstrekken geen persoonsgegevens aan partijen die gevestigd
        zijn buiten de EU.
      </p>

      <h2>Bewaartermijn</h2>
      <p>
        Wij bewaren je persoonsgegevens niet langer dan noodzakelijk voor het
        doel waarvoor deze zijn verstrekt dan wel op grond van de wet is
        vereist. Dat houdt in dat als je jouw account opheft we je
        persoonsgegevens niet langer bewaren.
      </p>

      <h2>Beveiliging</h2>
      <p>
        Wij nemen de bescherming van je gegevens serieus en hebben passende
        technische en organisatorische maatregelen genomen om uw
        persoonsgegevens te beschermen.
      </p>

      <h2>Rechten omtrent je gegevens</h2>
      <p>
        Met betrekking tot de persoonsgegevens die wij van je verwerken heb je
        de volgende rechten:
      </p>
      <ul class="list-inside list-disc">
        <li>Recht op inzage</li>
        <li>Recht op rectificatie</li>
        <li>Recht op wissing (vergetelheid)</li>
        <li>Recht op beperking van de verwerking</li>
        <li>Recht op overdraagbaarheid (dataportabiliteit)</li>
        <li>Recht van bezwaar</li>
        <li>Recht op intrekking toestemming</li>
        <li>Recht om een klacht in te dienen</li>
      </ul>

      <h2>Links naar andere websites en social media</h2>
      <p>
        Op onze website is een aantal verwijzingen naar websites van andere
        organisaties te vinden. Wij hebben geen controle over deze externe
        websites en zijn niet aansprakelijk voor de manier waarop deze partijen
        met persoonsgegevens omgaan.
      </p>

      <h2>Cookies en webstatistieken</h2>
      <p>
        Deze website maakt gebruik van cookies. Cookies zijn kleine
        (tekst)bestanden die een webpaginaserver bij bezoek aan een webpagina op
        je computer, tablet of ander elektronische apparaat (hierna “computer”)
        plaatst. Doordat een cookie op je computer staat, kan de webpaginaserver
        je computer herkennen.
      </p>
      <p class="mt-4">
        Cookies hebben een bepaalde geldigheidsduur vanaf het moment dat ze op
        een computer worden geplaatst. Nadat de geldigheidsduur verstrijkt,
        wordt de cookie verwijderd door je browser. Voor sommige cookies is de
        duur van de browsersessie de geldigheidsduur, maar er zijn ook cookies
        die langer geldig blijven zodat ze ook bij een volgend bezoek aan onze
        website kunnen worden uitgelezen. Voor zover wij met behulp van cookies
        persoonsgegevens van je verzamelen, verwerken wij die conform onze
        privacyverklaring.
      </p>

      <h2>Weigeren en verwijderen van cookies</h2>
      <p>
        Je kunt je browser zo instellen dat er geen cookies worden opgeslagen,
        je elke cookie kan toestaan of weigeren of dat alle cookies verwijderd
        worden bij het afsluiten van je browser. Meer informatie over hoe je
        cookies kunt beheren lees je op de website van de
        <a
          href="https://www.consumentenbond.nl/internet-privacy/cookies-verwijderen"
          target="_blank"
        >
          Consumentenbond
        </a>
        .
      </p>

      <h2>Google Analytics</h2>
      <p>
        Wij maken gebruik van Google Analytics om bij te houden hoe onze
        bezoekers de website gebruiken. Voor dit doel worden cookies geplaatst.
        Deze informatie wordt overgebracht naar en door Google. Google gebruikt
        deze informatie om bij te houden hoe onze website gebruikt wordt, om
        rapporten over de website aan ons te kunnen verstrekken.
      </p>

      <p class="mt-4">
        Het is Google niet toegestaan deze gegevens aan derden te verstrekken of
        voor andere Google diensten in te zetten.
      </p>

      <p class="mt-4">
        Lees voor meer informatie het
        <a href="https://policies.google.com/privacy?hl=nl" target="_blank">
          privacybeleid van Google
        </a>
        , alsook het specifieke
        <a
          href="https://support.google.com/analytics/answer/6004245?hl=nl"
          target="_blank"
        >
          privacybeleid van Google Analytics
        </a>
        . Zie
        <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">
          hier
        </a>
        voor meer informatie over de opt-out regeling van Google.
      </p>

      <h2>Facebook Pixel</h2>
      <p>
        Wij maken gebruik van een Facebook Pixel: een stukje code dat bijhoudt
        welke pagina's onze bezoekers bekijken. Deze data is niet gekoppeld aan
        specifieke gebruikers, maar wordt anoniem opgeslagen (maximaal 180
        dagen).
      </p>

      <p class="mt-4">
        Met de gegevens van de Facebook Pixel kunnen wij verschillende acties
        ondernemen:
      </p>

      <ul class="list-inside list-disc">
        <li>
          We kunnen onze doelgroep beter in kaart te brengen. Bij online
          campagnes kunnen we zo onze doelgroep makkelijker benaderen, door
          bijvoorbeeld gebruik te maken van zogenaamde ‘lookalikes’
        </li>
        <li>
          We kunnen inzien waar een gebruiker de website heeft verlaten. Met
          deze informatie kunnen we ons platform verbeteren.
        </li>
        <li>
          We kunnen een Facebook/Instagram advertentie opnieuw onder de aandacht
          brengen bij de juiste doelgroep.
        </li>
      </ul>
      <p class="mt-4">
        Wil je liever geen remarketing advertenties? Op Facebook kun je zelf
        advertenties uitschakelen die zijn gebaseerd op interesses en/of je
        relatie met specifieke adverteerders. Dit doe je in je
        voorkeursinstellingen voor advertenties. Op deze pagina's vind je meer
        informatie over het advertentiebeleid van Facebook en het aanpassen van
        je instellingen:
      </p>
      <p class="mt-4">
        <a href="https://nl-nl.facebook.com/policies/cookies/" target="_blank">
          https://nl-nl.facebook.com/policies/cookies/
        </a>
        <br />
        <a href="https://nl-nl.facebook.com/about/ads" target="_blank">
          https://nl-nl.facebook.com/about/ads
        </a>
        <br />
        <a href="https://www.facebook.com/ds/preferences/edit/" target="_blank">
          https://www.facebook.com/ds/preferences/edit/
        </a>
      </p>

      <h2>Inviplay is niet perfect</h2>
      <p>
        Mocht je een klacht hebben over de verwerking van je persoonsgegevens
        dan vragen wij je hierover direct contact met ons op te nemen. Wij
        proberen er dan samen met je uit te komen. Daarnaast heb je altijd het
        recht een klacht in te dienen bij de toezichthouder:
        <a
          target="_blank"
          href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons"
        >
          Autoriteit Persoonsgegevens
        </a>
        .
      </p>

      <h2>Wijzigingen</h2>
      <p>
        Ons privacy-beleid is afgestemd op het gebruik van en de mogelijkheden
        op deze website. Wij behouden ons het recht voor om deze
        privacyverklaring aan te passen. Het is daarom raadzaam om regelmatig
        deze privacyverklaring te raadplegen.
      </p>

      <h2>Contact</h2>
      <p>
        Indien je na het lezen van deze privacyverklaring vragen hebt, kun je
        hierover contact met ons opnemen via onderstaande contactgegevens:
      </p>

      <p class="mt-4">
        Inviplay B.V.
        <br />
        Voorveldselaan 2
        <br />
        3573PV Utrecht
        <br />
        <a href="mailto:contact@inviplay.nl">contact@inviplay.nl</a>
      </p>

      <p class="mt-4 text-sm"><em>Datum publicatie: 28/11/2021</em></p>
    </div>
    <tab-bar v-if="showTabBar" selected="profile" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import TabBar from '@/components/TabBar'

export default {
  name: 'PrivacyPolicy',
  components: {
    AppBar,
    TabBar
  },
  data() {
    return {
      showTabBar: this.$route.query.hideTabBar !== 'true'
    }
  }
}
</script>

<style scoped></style>
