<template>
  <div class="has-tab-bar">
    <app-bar
      title="E-mailadres bevestigen"
      :show-back-button="false"
      :show-profile-button="false"
    />
    <div class="container">
      <template v-if="loading">
        <spinner-loader />
        <h1>Even geduld...</h1>
      </template>
      <template v-else-if="tokenIsInvalid">
        <h1>E-mailadres niet bevestigd</h1>
        <p>De link die je hebt gebruikt is niet meer geldig</p>
        <button
          class="button button-primary mt-8"
          @click="$router.push(`/verify-email?uid=${uid}`)"
        >
          Stuur nieuwe e-mail
        </button>
      </template>
      <template v-else>
        <h1>E-mailadres niet bevestigd</h1>
        <p>Er ging iets mis bij het bevestigen van je e-mailadres</p>
      </template>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import { mapGetters } from 'vuex'
import api from '@/utils/api'

export default {
  name: 'ConfirmAccount',
  components: { AppBar, SpinnerLoader },
  data() {
    return {
      uid: this.$route.query.uid,
      token: this.$route.query.token,
      tokenIsInvalid: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  watch: {},
  created: async function () {
    if (this.isAuthenticated) {
      await api.post('participant/logout')
    }
    this.loading = true
    try {
      const resp = await api.get(
        `/participant/confirm?uid=${this.uid}&token=${this.token}`
      )
      if (resp?.status === 204) {
        const { query } = this.$route
        delete query.uid
        delete query.token
        this.$router.push({ path: '/login', query })
      } else {
        this.tokenIsInvalid = true
      }
    } catch (err) {
      this.tokenIsInvalid = true
    }
    this.loading = false
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
