<template>
  <div class="screen-profile-view has-tab-bar">
    <app-bar :title="name" :show-back-button="true" />
    <div class="container">
      <div class="wrapper-profile-avatar">
        <template v-if="avatar === null">
          <div class="no-avatar">
            <round-icon
              :size="140"
              :border-size="0"
              background-color="#E6E6E6"
              icon-class="fal fa-user"
            />
          </div>
        </template>
        <template v-else>
          <div
            class="image-avatar"
            :style="{ 'background-image': 'url(' + avatar + ')' }"
          />
        </template>
      </div>
      <h1 class="page-title text-center">Sporten</h1>
      <ul class="activity-list">
        <li v-for="activity in activities" :key="activity.id">
          <round-icon
            :size="55"
            background-color="#009B6D"
            icon-color="#FFF"
            :icon-class="activity_type.icon"
          />
          <span class="activity-title">{{ activity.name }}</span>
        </li>
      </ul>
    </div>
    <tab-bar selected="events" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import TabBar from '@/components/TabBar'
import RoundIcon from '@/components/RoundIcon'
import { PARTICIPANT_VIEW } from '../store/actions/participant'
import { mapState } from 'vuex'

export default {
  name: 'ProfileView',
  components: {
    AppBar,
    RoundIcon,
    TabBar
  },
  data() {
    return {
      name: null,
      avatar: null,
      activities: []
    }
  },
  computed: {
    ...mapState({
      profile: (state) => state.participant.profile
    })
  },
  watch: {
    profile(profile) {
      this.name = profile.firstName + ' ' + profile.lastName
      this.avatar = profile.avatar
      this.activities = profile.activity_types
    }
  },
  created() {
    this.$store.dispatch(PARTICIPANT_VIEW, this.$route.params.id)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.page-title {
  margin: 30px auto;
}
ul.activity-list {
  list-style: none;
  padding: 0 0 0 30px;
}
ul.activity-list li {
  position: relative;
  margin-bottom: 30px;
  height: 55px;
}
ul.activity-list li .activity-title {
  font-size: 1.25em;
  position: absolute;
  @include transform(translateY(-50%));
  top: 50%;
  left: 80px;
  right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
