<template>
  <div class="has-tab-bar">
    <app-bar
      title=""
      :show-back-button="true"
      back-button-path="/profile-own"
    />
    <spinner-loader v-if="loading" />
    <div class="container">
      <h1>Mijn strippenkaarten</h1>
      <tab-navigation
        v-if="showTabNavigation"
        :tabs="tabs"
        :active-tab="activeTab"
        class="mb-8"
        @toggle="changeTab"
      />
      <template v-if="activeTab === CREATED_CARDS">
        <div v-if="createdCards.length" class="mb-8">
          <card-item
            v-for="card in createdCards"
            :key="card.id"
            :card="card"
            :show-buy-button="false"
            @click-card="gotoCard(card.id)"
          />
        </div>
        <div v-else class="mb-8">
          Je hebt nog geen strippenkaarten aangemaakt. Klik op de knop hieronder
          om een nieuwe strippenkaart aan te maken
        </div>
        <button class="button button-primary w-full" @click="routeToCreateCard">
          Maak nieuwe strippenkaart
        </button>
      </template>
      <template v-else-if="activeTab === PURCHASED_CARDS">
        <div v-if="purchasedCards.length" class="mb-8">
          <card-item
            v-for="card in purchasedCards"
            :key="card.id"
            :card="card"
            :show-buy-button="false"
            :show-description="false"
            :show-remaining="true"
            @click-card="gotoCard(card.id)"
          />
        </div>
        <div v-else class="mb-8">
          Je hebt nog geen strippenkaarten gekocht. Deze kun je kopen bij
          activiteiten waarbij je gebruik kunt maken van een strippenkaart.
        </div>
        <button
          v-if="activeTab === CREATED_CARDS"
          class="button button-primary w-full"
          @click="routeToCreateCard"
        >
          Maak nieuwe strippenkaart
        </button>
      </template>
    </div>
    <tab-bar selected="" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import { mapGetters, mapState } from 'vuex'
import { CREDITCARD_GET_ALL } from '../store/actions/creditcard'
import CardItem from '@/components/CardItem'
import TabNavigation from '@/components/TabNavigation'
import { PARTICIPANT_GET_CREDITCARDS } from '../store/actions/participant'

const CREATED_CARDS = 'CREATED_CARDS'
const PURCHASED_CARDS = 'PURCHASED_CARDS'

export default {
  name: 'ProfileCards',
  components: { AppBar, SpinnerLoader, TabBar, CardItem, TabNavigation },
  data() {
    return {
      loading: false,
      showTabNavigation: true,
      tabs: [
        { key: PURCHASED_CARDS, value: 'Gekocht' },
        { key: CREATED_CARDS, value: 'Aangemaakt' }
      ],
      CREATED_CARDS: CREATED_CARDS,
      PURCHASED_CARDS: PURCHASED_CARDS,
      activeTab: PURCHASED_CARDS
    }
  },
  computed: {
    ...mapGetters(['authId']),
    ...mapState({
      createdCards: (state) => state.creditcard.all,
      purchasedCards: (state) => state.participant.purchasedCreditcards
    })
  },
  watch: {},
  created: function () {
    this.$store.dispatch(CREDITCARD_GET_ALL, { ownerId: parseInt(this.authId) })
    this.$store.dispatch(PARTICIPANT_GET_CREDITCARDS)
  },
  methods: {
    changeTab(target) {
      this.activeTab = target
    },
    routeToCreateCard() {
      this.$router.push('/cards/new')
    },
    gotoCard(cardId) {
      this.$router.push(`/cards/${cardId}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
