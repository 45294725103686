<template>
  <div class="screen-profile-own has-tab-bar">
    <template v-if="profile !== null">
      <app-bar
        :title="`${profile.firstName} ${profile.lastName}`"
        :show-back-button="true"
        :show-profile-button="false"
        :back-button-path="origin"
      />
      <div class="container">
        <div class="wrapper-profile-avatar">
          <template v-if="profile.avatarUrl === null">
            <div class="no-avatar">
              <round-icon
                :size="140"
                :border-size="0"
                background-color="#E6E6E6"
                icon-class="fal fa-user"
              />
            </div>
          </template>
          <template v-else>
            <div
              class="image-avatar"
              :style="{
                'background-image': 'url(' + profile.avatarUrl + ')'
              }"
            />
          </template>
        </div>

        <h1 class="page-title text-center">Mijn sporten</h1>
        <ul class="activity-list">
          <li
            v-for="activity_type in profile.activity_types"
            :key="activity_type.id"
          >
            <round-icon
              :size="55"
              background-color="#009B6D"
              icon-color="#FFF"
              :icon-class="activity_type.icon"
            />
            <p class="activity-title">
              {{ activity_type.name }}
            </p>
          </li>
        </ul>

        <hr class="my-3.5 border-gray-300" />

        <ul class="list-none p-0 mt-8">
          <li class="list-item">
            <a href="#" @click.prevent="$router.push('/profile-edit')">
              Profiel aanpassen
            </a>
            <i class="fas fa-chevron-right" />
          </li>
          <li v-if="creditcardsEnabled" class="list-item">
            <a href="#" @click.prevent="$router.push('/profile/cards')">
              Mijn strippenkaarten
            </a>
            <i class="fas fa-chevron-right" />
          </li>
          <template v-if="paymentProvider === 'opp'">
            <li class="list-item">
              <a href="#" @click.prevent="$router.push('/profile/settlements')">
                Uitbetalingen
              </a>
              <i class="fas fa-chevron-right" />
            </li>
          </template>
          <li class="list-item">
            <a href="#" @click.prevent="$router.push('/feedback')">Feedback</a>
            <i class="fas fa-chevron-right" />
          </li>
          <li class="list-item">
            <a href="#" @click.prevent="$router.push('/privacy-policy')">
              Privacy
            </a>
            <i class="fas fa-chevron-right" />
          </li>
          <li class="list-item">
            <a href="#" @click.prevent="$router.push('/terms-and-Conditions')">
              Algemene voorwaarden
            </a>
            <i class="fas fa-chevron-right" />
          </li>
          <li class="list-item">
            <a href="#" @click.prevent="logout">Uitloggen</a>
            <i class="fas fa-chevron-right" />
          </li>
        </ul>
      </div>
    </template>
    <spinner-loader v-else />
    <tab-bar selected="profile" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import TabBar from '@/components/TabBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import RoundIcon from '@/components/RoundIcon'
import { mapState } from 'vuex'
import { AUTH_LOGOUT } from '../store/actions/auth'
import { PARTICIPANT_OWN } from '../store/actions/participant'
import * as Sentry from '@sentry/vue'
import config from '@/utils/config'

export default {
  name: 'ProfileOwn',
  components: {
    AppBar,
    TabBar,
    RoundIcon,
    SpinnerLoader
  },
  data() {
    return {
      profile: null,
      origin: this.$route.query.origin,
      creditcardsEnabled: config.featureFlags.creditcards,
      paymentProvider: config.paymentProvider
    }
  },
  computed: {
    ...mapState({
      ownProfile: (state) => state.participant.ownProfile
    }),
    profileWatchable() {
      return this.ownProfile, Date.now()
    }
  },
  watch: {
    profileWatchable() {
      if (Object.keys(this.ownProfile).length) {
        this.profile = this.ownProfile
      } else {
        this.$router.push('/login')
      }
    }
  },
  created() {
    this.$store.dispatch(PARTICIPANT_OWN)
  },
  methods: {
    logout: function () {
      this.$store
        .dispatch(AUTH_LOGOUT)
        .then(() => {
          if (process.env.NODE_ENV !== 'development') {
            Sentry.configureScope((scope) => scope.setUser(null))
          }
          this.$router.push('/')
        })
        .catch(() => this.$router.push('/'))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.list-item {
  @apply relative text-lg font-semibold;
}
.list-item a {
  @apply block py-4 pr-6 whitespace-nowrap overflow-hidden overflow-ellipsis;
}
.list-item i {
  @apply text-green-600 right-0 absolute top-1/2 -z-1 transform -translate-y-1/2 text-2xl;
}
.activity-list {
  list-style: none;
  padding: 0;
  text-align: center;
  margin-top: 30px;
}
.activity-list li {
  display: inline-block;
}
.activity-list li .activity-title {
  font-weight: 500;
  font-size: 0.688rem;
  width: 75px;
  margin-top: 15px;
}
.profile-link-list {
  list-style: none;
  padding: 0;
  margin-top: 30px;
}
.profile-link-list li {
  font-size: 1.25rem;
  position: relative;
}
.profile-link-list li a {
  color: $ipBlack;
  display: block;
  font-weight: 600;
  padding: 15px 20px 15px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.profile-link-list li i {
  color: $ipGreen;
  font-size: 1.75rem;
  right: 0;
  position: absolute;
  @include transform(translateY(-50%));
  top: 50%;
  z-index: -1;
}
</style>
