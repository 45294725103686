<template>
  <div class="screen-password-forgot">
    <div class="container">
      <div class="logo">
        <img src="@/assets/images/logo.png" />
      </div>
      <form @submit.prevent="passwordRequestReset">
        <div class="form-group">
          <input
            v-model="email"
            type="email"
            class="form-control no-border"
            placeholder="Email"
            required
          />
        </div>

        <button
          type="submit"
          class="btn btn-white btn-block button-password-reset margin-bottom-15"
        >
          Reset wachtwoord
        </button>
        <p class="text-or">of</p>
        <a
          href="#"
          class="btn btn-green-border-white btn-block button-login"
          @click.prevent="$router.push('/login')"
        >
          terug naar inloggen
        </a>
      </form>
    </div>
    <div class="background-entrance">
      <div class="bottom">
        <div class="circle" />
        <div class="dash" />
      </div>
    </div>
  </div>
</template>

<script>
import { PARTICIPANT_PASSWORD_RESET_REQUEST } from '../store/actions/participant'
import _ from 'lodash'

export default {
  name: 'Register',
  components: {},
  data() {
    return {
      email: null
    }
  },
  methods: {
    passwordRequestReset: function () {
      if (this.isValid()) {
        this.$store
          .dispatch(PARTICIPANT_PASSWORD_RESET_REQUEST, this.email)
          .then(() => {
            alert('Er is een email gestuurd met verdere instructies')
            this.$router.push('/login')
          })
          .catch((error) => {
            if (error.response !== undefined) {
              alert(
                'Er is iets fout gegegaan het aanvragen van een niet wachtwoord: ' +
                  error.response.data.error.message
              )
            }
          })
      }
    },
    isValid: function () {
      let isValid = true
      let message = null

      // Check fields are valid
      if (_.isEmpty(this.email)) {
        isValid = false
        message = 'Email is verplicht'
      }

      if (message !== null) {
        alert(message)
      }

      return isValid
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.screen-password-forgot {
}
.screen-password-forgot .logo {
  width: 270px;
  margin: 0 auto;
  padding: 40px 0;
  max-width: 100%;
}
.screen-password-forgot .logo img {
  width: 270px;
  max-width: 100%;
}
.screen-password-forgot .text-or {
  color: $ipWhite;
  font-size: 1.25rem;
  margin: 15px 0;
  text-align: center;
}
.screen-password-forgot
  .wrapper-activity-list
  .activity-list
  li
  .activity-title {
  color: $ipWhite;
}
.screen-password-forgot .button-password-reset {
  margin-top: 15px;
}
.screen-password-forgot .button-login {
  margin-bottom: 25px;
}
</style>
