<template>
  <div
    class="screen-terms-and-conditions"
    :class="{ 'has-tab-bar': showTabBar }"
  >
    <app-bar title="Algemene voorwaarden" :show-back-button="true" />
    <div class="container">
      <h1>Algemene voorwaarden</h1>

      <p>
        Inviplay brengt mensen op een laagdrempelige manier bij elkaar om samen
        te gaan sporten en bewegen. Hierbij hanteren wij hierbij een paar
        spelregels.
      </p>

      <p class="mt-4">
        Het doel van Inviplay is dat mensen meer gaan bewegen om zo de
        gezondheid (zowel fysiek als mentaal) van mensen te helpen verbeteren.
        We maken hierbij gebruik van een platform waarop mensen zelf hun
        activiteiten kunnen plaatsen en anderen daarvoor kunnen uitnodigen. We
        maken gebruik van de openbare ruimte (bijvoorbeeld een park) en we
        bieden sportlocaties aan.
      </p>

      <p class="mt-4">
        Inviplay kan speciale eisen stellen aan het plaatsen van
        sportactiviteiten door commerciële partijen. Als dat voor jou het geval
        is, stellen we je daarvan op de hoogte. Als je niet aan die eisen
        voldoet dan mogen wij je advertenties en/of je account opheffen.
      </p>

      <h2>Profiel</h2>
      <p>
        Je profiel op Inviplay is persoonlijk en niet overdraagbaar. Je bent wie
        je zegt dat je bent. We respecteren je privacy (zie onze
        privacyverklaring). Inviplay is niet verantwoordelijk voor oneigenlijk
        gebruik van je profiel, behandel je wachtwoord dus vertrouwelijk en met
        zorg.
      </p>

      <h2>Contact met andere Inviplay-ers</h2>
      <p>
        Communiceer op Inviplay zoals je dat in persoon ook zou doen, dus geen
        grof taalgebruik. Inviplay mag ingrijpen in berichten en/of deze
        weghalen als ze onfatsoenlijk of tegen de wet zijn. Je berichten zijn
        niet versleuteld en voor iedereen openbaar (en dus toegankelijk). We
        raden af dingen te bespreken via Inviplay berichten die je als zeer
        persoonlijk en/of vertrouwelijk ziet.
      </p>

      <p class="mt-4">
        Ook tijdens de Inviplay activiteiten ga je respectvol om met de ander.
        Inviplay kan blessures niet voorkomen, jij en je teamgenoten kunnen dat
        wel. Inviplay gaat uit van de innerlijke scheidsrechter, gun bij twijfel
        de ander de winst. Lol hebben in sporten en bewegen staat bij Inviplay
        voorop, meedoen is belangrijker dan winnen.
      </p>

      <h2>Huren sportlocatie</h2>
      <p>
        Je gaat zorgvuldig om met de gehuurde sportlocatie en andermans spullen
        (bijvoorbeeld sportmaterialen). De sportlocaties die je via Inviplay
        boekt zijn in beheer bij externe partijen (zoals verenigingen, gemeenten
        of sportbedrijven). Hun regels gelden dus. Meldt schade z.s.m. bij de
        externe partij. Bij schade aan materiaal of aan de locatie zelf kan de
        externe verhuur partij jou aansprakelijk stellen.
      </p>

      <p class="mt-4">
        Na gebruik dien je de sportlocatie op te leveren in de staat waarin de
        sportlocatie was voordat je er gebruik van maakte. Gebruikte spullen
        (zoals toestellen en sportmaterialen) moeten weer netjes op dezelfde
        plek worden opgeborgen. Gebruik de juiste sportschoenen, gebruik
        bijvoorbeeld geen schoenen met zwarte zolen in een binnenlocatie. En
        veroorzaak uiteraard geen overlast voor anderen.
      </p>

      <h2>Aansprakelijkheid</h2>
      <p>
        Gebruik je gezond verstand en doe geen dingen die je normaal ook niet
        doet. Je bent zelf verantwoordelijk voor je eigen veiligheid. Inviplay
        en de externe verhuur partij zijn juridisch niet aansprakelijk voor
        welke schade dan ook (bijvoorbeeld in de vorm van blessureleed/ letsel,
        eigendomsschade of verloren spullen).
      </p>

      <h2>Feedback</h2>
      <p>
        Als je iets ziet wat beter kan, dan horen we dat graag van je. Omdat er
        constant aan Inviplay wordt gewerkt, kan de site van tijd tot tijd
        wijzigen of eventjes niet werken. We kunnen nieuwe dingen toevoegen of
        juist dingen weghalen omdat ze niet nuttig blijken.
      </p>

      <h2>Berichten van Inviplay</h2>
      <p>
        Omdat we ons enthousiasme over Inviplay graag delen sturen wij berichten
        om je op de hoogte te houden van nieuwe mogelijkheden (bijvoorbeeld als
        we sporten hebben toegevoegd die je kunt volgen). Bij profiel aanpassen
        kun je je apart af- en aanmelden voor de nieuwsbrief. Deze nieuwsbrief
        sturen wij sporadisch. Je hoeft dus niet bang te zijn dat je mailbox vol
        loopt met berichten over Inviplay.
      </p>

      <h2>Betalen via Inviplay</h2>
      <p>
        Inviplay maakt gebruik van Online Payment Platform. Betalingen via
        Inviplay vallen dus onder de algemene voorwaarden van Online Payment
        Platform. Bekijk de algemene voorwaarden van Online Payment Platform
        <a
          class="link"
          target="_blank"
          href="https://onlinepaymentplatform.com/media/cnt/opp-gebruiksvoorwaarden-versie-04112022_2.pdf"
        >
          hier
        </a>
        .
      </p>

      <h2>Contact</h2>
      <p>
        Indien je na het lezen van deze algemene voorwaarden vragen hebt, kun je
        hierover contact met ons opnemen via onderstaande contactgegevens:
      </p>

      <p class="mt-4">
        Inviplay B.V.
        <br />
        Minahassastraat 16
        <br />
        3531KX Utrecht
        <br />
        <a href="mailto:contact@inviplay.nl">contact@inviplay.nl</a>
      </p>

      <p class="mt-4 text-sm"><em>Datum publicatie: 22/08/2023</em></p>
    </div>
    <tab-bar v-if="showTabBar" selected="profile" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import TabBar from '@/components/TabBar'

export default {
  name: 'TermsAndConditions',
  components: {
    AppBar,
    TabBar
  },
  data() {
    return {
      showTabBar: this.$route.query.hideTabBar !== 'true'
    }
  }
}
</script>

<style scoped></style>
