<template>
  <div class="has-tab-bar">
    <app-bar
      title="Welkom"
      :show-back-button="false"
      :show-profile-button="false"
    />
    <spinner-loader v-if="loading" />
    <div class="container">
      <h1>Maak een wachtwoord aan</h1>
      <div>Met dit wachtwoord kun je vervolgens inloggen op Inviplay</div>
      <input-field
        v-model="password"
        class="mt-4"
        type="password"
        default-value="Wachtwoord"
      />
      <input-field
        v-model="repeatPassword"
        class="mt-4"
        type="password"
        default-value="Herhaal wachtwoord"
      />
      <div v-if="passwordsNotEqual" class="text-red-800 mt-3 text-sm">
        De wachtwoorden komen niet overeen
      </div>
      <div v-if="passwordFormatError" class="text-red-800 mt-3 text-sm">
        Het wachtwoord moet minimaal 8 tekens, 1 hoofdletter en 1 getal bevatten
      </div>

      <button
        :disabled="loading || !password || !repeatPassword"
        class="button button-primary mt-8"
        @click="createPassword"
      >
        Maak wachtwoord aan
      </button>
    </div>
    <modal
      :show="showErrorModal"
      @close="
        () => {
          showErrorModal = false
          showNewLinkButton = false
        }
      "
    >
      <template v-slot:header>Er ging iets fout...</template>
      <p class="text-sm">
        {{ errorText }}
      </p>
      <template v-if="showNewLinkButton" v-slot:footer>
        <button
          class="button button-primary w-full"
          @click="sendNewPasswordResetLink"
        >
          Stuur mij een nieuwe link
        </button>
        <button
          class="button button-secondary w-full"
          @click="
            () => {
              showErrorModal = false
              showNewLinkButton = false
            }
          "
        >
          Annuleren
        </button>
      </template>
    </modal>
    <modal
      :show="showSuccesModal"
      @close="
        () => {
          showSuccesModal = false
          showLoginButton = false
        }
      "
    >
      <template v-slot:header>Gelukt!</template>
      <p class="text-sm">
        {{ successText }}
      </p>
      <template v-if="showLoginButton" v-slot:footer>
        <button
          class="button button-primary w-full"
          @click="$router.push('/login')"
        >
          Inloggen
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import InputField from '@/components/InputField'
import Modal from '@/components/Modal'
import { mapState } from 'vuex'
import api, { setAuthToken } from '@/utils/api'

export default {
  name: 'SetExternalPassword',
  components: { AppBar, SpinnerLoader, InputField, Modal },
  data() {
    return {
      loading: false,
      participantId: this.$route.query['id'],
      resetToken: this.$route.query['token'],
      password: '',
      repeatPassword: '',
      showErrorModal: false,
      showSuccesModal: false,
      passwordsNotEqual: false,
      passwordFormatError: false,
      errorText: undefined,
      successText: undefined,
      showNewLinkButton: false,
      showLoginButton: false
    }
  },
  computed: {
    ...mapState({})
  },
  watch: {},
  created: function () {},
  methods: {
    passwordIsValid(password) {
      // Check if password contains at least one capital letter and one number
      const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
      return password.match(re)
    },
    createPassword: async function () {
      if (this.password !== this.repeatPassword) {
        this.passwordsNotEqual = true
        return
      } else if (!this.passwordIsValid(this.password)) {
        this.passwordFormatError = true
        return
      } else {
        this.passwordsNotEqual = false
        this.passwordFormatError = false
        this.loading = true
        setAuthToken(this.resetToken)
        try {
          const resp = await api.post('/participant/reset-password', {
            newPassword: this.password
          })
          if (resp) {
            this.showSuccesModal = true
            this.successText =
              'Het wachtwoord is opnieuw ingesteld, je kunt nu inloggen.'
            this.showLoginButton = true
          } else {
            this.showErrorModal = true
            this.errorText =
              'Er is iets fout gegaan bij het aanmaken van het wachtwoord, mogelijk is de link verlopen. Klik op de knop hieronder om een nieuwe link naar je e-mailadres te sturen.'
            this.showNewLinkButton = true
          }
        } catch (e) {
          this.showErrorModal = true
          this.errorText =
            'Er is iets fout gegaan bij het aanmaken van het wachtwoord, mogelijk is de link verlopen. Klik op de knop hieronder om een nieuwe link naar je e-mailadres te sturen.'
          this.showNewLinkButton = true
        }
        this.loading = false
      }
    },
    sendNewPasswordResetLink: async function () {
      this.showErrorModal = false
      this.showNewLinkButton = false
      this.loading = true
      try {
        const resp = await api.post(
          `/participant/${this.participantId}/create-reset-token`
        )
        if (resp) {
          this.showSuccesModal = true
          this.successText =
            'Er is een nieuwe link naar je e-mailadres gestuurd.'
        } else {
          this.showErrorModal = true
          this.errorText =
            'Er is iets fout gegaan bij het versturen van de nieuwe link. Probeer het later opnieuw.'
        }
      } catch (e) {
        this.showErrorModal = true
        this.errorText =
          'Er is iets fout gegaan bij het versturen van de nieuwe link. Probeer het later opnieuw.'
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
