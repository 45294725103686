<template>
  <div class="screen-login">
    <div class="container">
      <div class="logo">
        <img src="@/assets/images/logo.png" />
      </div>
      <!-- <div class="login-text">
        <p>
          Meld je gratis aan op het gezelligste recreanten netwerk van
          Nederland:
        </p>
        <ul>
          <li>Bekijk wie er in jouw buurt al samen sporten</li>
          <li>Ontvang uitnodigingen voor favoriete sportactiviteiten</li>
          <li>Maak zelf een activiteit aan!</li>
        </ul>
      </div> -->
      <form @submit.prevent="login">
        <div class="form-group">
          <input
            v-model="email"
            type="email"
            class="form-control no-border"
            placeholder="Email"
            required
          />
        </div>
        <div class="form-group">
          <input
            v-model="password"
            type="password"
            class="form-control no-border"
            placeholder="Wachtwoord"
            required
          />
          <div class="wrapper-password-lost">
            <a
              href="#"
              class="link-password-lost"
              @click.prevent="$router.push('/password-forgot')"
            >
              Ik ben mijn wachtwoord kwijt
            </a>
          </div>
        </div>
        <button
          type="submit"
          class="btn btn-white btn-block button-login btn-round"
        >
          inloggen
        </button>
        <a
          href="#"
          class="btn btn-green-border-white btn-block button-register btn-round"
          @click.prevent="routeToRegister"
        >
          gratis aanmelden
        </a>
      </form>
    </div>
    <div class="background-entrance">
      <div class="bottom">
        <div class="circle" />
        <div class="dash" />
      </div>
    </div>
    <modal :show="showEmailConfirmModal" @close="showEmailConfirmModal = false">
      <template v-slot:header>Gelukt!</template>
      <div>
        Je e-mailadres is bevestigd en je kunt nu inloggen om verder te gaan.
      </div>
    </modal>
    <modal
      :show="showAccountPausedModal"
      @close="showAccountPausedModal = false"
    >
      <template v-slot:header>Er gaat iets fout...</template>
      <div>
        Je account is gedeactiveerd. Stuur een email naar contact@inviplay.nl
        als je je account weer wilt activeren.
      </div>
    </modal>
    <modal
      :show="showWrongCredentialsModal"
      @close="showWrongCredentialsModal = false"
    >
      <template v-slot:header>Er gaat iets fout...</template>
      <div>
        Controleer of je inloggegevens goed zijn en probeer het opnieuw.
      </div>
    </modal>
    <spinner-loader v-if="authLoading" />
  </div>
</template>

<script>
import { AUTH_REQUEST } from '../store/actions/auth'
import SpinnerLoader from '@/components/SpinnerLoader'
import { mapState } from 'vuex'
import Modal from '@/components/Modal'
import store from '../store'
import * as Sentry from '@sentry/vue'

export default {
  name: 'Login',
  components: { SpinnerLoader, Modal },
  data() {
    return {
      // participant: null,
      email: process.env.NODE_ENV === 'development' ? 'lennert@lemonit.nl' : '',
      password: process.env.NODE_ENV === 'development' ? 'ikbenLennert4' : '',
      redirectTo: this.$route.query.redirectTo,
      origin: this.$route.query.origin,
      defaultRedirectTo: 'events',
      showEmailConfirmModal: false,
      showAccountPausedModal: false,
      showWrongCredentialsModal: false
    }
  },
  computed: {
    ...mapState({
      authLoading: (state) => state.auth.status === 'loading',
      ownProfile: (state) => state.participant.ownProfile
    })
  },
  created() {
    if (store.getters.isAuthenticated) {
      this.$router.push(this.redirectTo || this.defaultRedirectTo)
    }
    if (this.origin === 'confirm-email') {
      this.showEmailConfirmModal = true
    }
  },
  methods: {
    login: function () {
      const { email, password } = this
      this.$store
        .dispatch(AUTH_REQUEST, { email, password })
        .then((resp) => {
          if (process.env.NODE_ENV !== 'development') {
            Sentry.setUser({
              id: resp.data && resp.data.userId,
              securityHash: resp.data && resp.data.id
            })
          }
          // check if user is verified
          if (resp.data) {
            this.$router.push(this.redirectTo || this.defaultRedirectTo)
          }
        })
        .catch((err) => {
          if (err?.code === 'LOGIN_FAILED_EMAIL_NOT_VERIFIED') {
            this.$router.push(`/verify-email?uid=${err?.details?.userId}`)
          } else if (err?.code === 'LOGIN_FAILED_ACCOUNT_PAUSED') {
            this.showAccountPausedModal = true
          } else {
            this.showWrongCredentialsModal = true
          }
        })
    },
    routeToRegister: function () {
      this.$router.push({
        path: '/register-preferences',
        query: this.$route.query
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.screen-login {
  padding-bottom: 30px;
}

.screen-login .login-text {
  color: $ipWhite;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  text-align: left;
  font-weight: 600;
}

.screen-login .logo {
  width: 270px;
  margin: 0 auto;
  padding: 40px 0;
  max-width: 100%;
  display: block;
}

.screen-login .logo img {
  width: 270px;
  max-width: 100%;
}

.screen-login .button-login {
  margin-top: 25px;
}
.screen-login .button-register {
  margin-top: 15px;
}
.screen-login .button-margin-bottom {
  margin-bottom: 35px;
}

.screen-login .text-or {
  color: $ipWhite;
  font-size: 1.25rem;
  margin: 15px 0;
  text-align: center;
}

.screen-login .wrapper-password-lost {
  text-align: center;
  font-weight: 600;
  padding-top: 5px;
}

.screen-login .wrapper-password-lost .link-password-lost {
  color: $ipWhite;
}
</style>
