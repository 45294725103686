<template>
  <div class="has-tab-bar">
    <app-bar
      title="E-mailadres bevestigen"
      :show-back-button="false"
      :show-profile-button="false"
    />
    <div class="container">
      <template v-if="loading">
        <h1>Even geduld...</h1>
        <spinner-loader />
      </template>
      <template v-else-if="verifyEmailSent">
        <h1>E-mail verstuurd</h1>
        <p>
          Er is een e-mail verstuurd naar je e-mailadres. Klik op de link in de
          e-mail om je e-mailadres te bevestigen.
        </p>
      </template>
      <template v-else-if="verifyEmailError">
        <h1>E-mail versturen mislukt</h1>
        <p>
          Er ging iets mis bij het versturen van de e-mail. Neem contact met ons
          op via contact@inviplay.nl en we helpen je zo snel mogelijk verder.
        </p>
      </template>
      <template v-else>
        <h1>Bevestig je e-mailadres</h1>
        <p>
          Je bent bijna klaar om te beginnen. Je hoeft alleen nog maar je
          aanmelding te bevestigen door je op onderstaande knop te klikken.
        </p>
        <button class="button button-primary mt-8" @click="verifyEmail">
          Bevestig je e-mailadres
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import { mapState } from 'vuex'
import api from '@/utils/api'

export default {
  name: 'VerifyEmail',
  components: { AppBar, SpinnerLoader },
  data() {
    return {
      loading: false,
      uid: this.$route.query.uid,
      verifyEmailSent: false,
      verifyEmailError: false
    }
  },
  computed: {
    ...mapState({})
  },
  watch: {},
  created: function () {},
  methods: {
    verifyEmail: async function () {
      this.loading = true
      try {
        const resp = await api.post(`/participant/${this.uid}/verify-email`)
        if (resp.status === 204) {
          this.verifyEmailSent = true
        }
      } catch (err) {
        this.verifyEmailError = true
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
